//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RegionListTable from '@/modules/cities/components/regions-list-table.vue';
import RegionFormModal from '@/modules/cities/components/regions-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { CitiesPermissions } from '@/modules/cities/cities-permissions';
// import * as firebase from 'firebase';

export default {
  name: 'app-regions-list-page',

  components: {
    [RegionListTable.name]: RegionListTable,
    [RegionFormModal.name]: RegionFormModal,
  },
  data() {
    return {
      modalVisible: false,
      dialogVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new CitiesPermissions(this.currentUser).create;
    },
  },

  // async created() {
  //   await firebase.firestore().collection('city').onSnapshot(async querySnapshot => {
  //      console.log(`Received query snapshot of size ${querySnapshot.size}`);
  //     this.doFilterInBackground();
  //   }, err => {
  //      console.log(`Encountered error: ${err}`);
  //   });
  // },
  async mounted() {
    // await this.doFetchCitiesAndRegions()
    await this.doFetchRegions()
  },
  methods: {
    ...mapActions({
      doFetchCitiesAndRegions: 'citiesRegions/list/doFetchCitiesAndRegions',
      doFetchRegions: 'citiesRegions/list/doFetchRegions',
    }),
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = { citiesType: 'branch' }
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = { citiesType: 'branch' }
      return this.doFetchInBackground({
        filter,
      });
    },

  },

};
