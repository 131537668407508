import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
// import DecimalRangeField from '@/shared/fields/decimal-range-field';
// import DecimalField from '@/shared/fields/decimal-field';
// import ImageField from '@/shared/fields/image-field';
// import ImagesField from '@/shared/fields/images-field';
// import IntegerField from '@/shared/fields/integer-field';
import StringArrayField from '@/shared/fields/string-array-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import BooleanField from '@/shared/fields/boolean-field';
import JsonField from '@/shared/fields/json-field';


function label(name) {
  return i18n(`entities.city.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new JsonField('name', label('name'), {}),
  nameAr: new StringField('nameAr', label('nameAr'), {}),
  nameEn: new StringField('nameEn', label('nameEn'), {}),
  regions: new StringArrayField('regions', label('regions'), {}),


  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy',label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt',label('updatedAt')), 
  createdAtRange: new DateTimeRangeField( 'createdAtRange', label('createdAtRange')),
};

export class CitiesModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
